@use '../../styles/variables';
@use '../../styles/mixins';

.section__about {
  padding: 4rem 8rem;
  display: flex;

  @include mixins.respond(tab-land) {
    padding: 2rem 4rem;
  }

  @include mixins.respond(tab-port) {
    padding: 1rem 2rem;
  }

  @include mixins.respond(phone) {
    display: block;
    padding: 0.5rem 1rem;
  }

  &--header {
    display: block;
    text-align: center;
    padding: 2rem 0;
    font-size: variables.$default-font-size-6;
    font-weight: 700;
    background: linear-gradient(
      to right,
      variables.$color-primary-2,
      variables.$color-primary-8,
      variables.$color-primary-1,
      variables.$color-primary-6
    );
    // color: transparent;
    color: rgba(0, 0, 0, 0.1);
    background-clip: text;
    -webkit-background-clip: text;
  }

  &--part {
    width: 50%;
    margin-bottom: 2rem;
    border-radius: 5px;
    box-shadow: 10px 10px 20px variables.$color-gray-3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    @include mixins.respond(phone) {
      width: 100%;
      height: 40rem;
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      font-size: variables.$default-font-size-3;
      color: variables.$color-primary-7;
      font-weight: 700;
      font-style: italic;

      @include mixins.respond(phone) {
        padding: 1rem 0;
      }
    }

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
    }

    &-text {
      width: 100%;
      height: 100%;
      @include mixins.flexCenter;
      border-radius: 5px;
      padding: 2rem 2rem;

      @include mixins.respond(tab-port) {
        padding: 2rem 4rem;
      }
    }
    &-image {
      width: 70%;
      height: 100%;
      @include mixins.flexCenter;
      border-radius: 5px;

      @include mixins.respond(phone) {
        display: none;
      }
    }
  }

  &--text {
    font-size: 1.4rem;
    color: variables.$color-gray-6;
    line-height: 3rem;
    font-weight: 500;

    @include mixins.respond(tab-land) {
      padding: 0;
    }

    & > h2 {
      font-size: variables.$default-font-size-3;
      color: variables.$color-gray-7;
      margin-bottom: 5rem;
    }
  }

  &--image {
    @include mixins.flexCenter;
    position: relative;
    width: 100%;
    height: 100%;

    & > img {
      width: 70%;
      height: 60%;
      outline: 1rem solid variables.$color-primary-0;
      outline-offset: 1.5rem;
      border-radius: 5px;
    }

    &-1 {
      z-index: 10;
      transition: all 0.2s;

      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}
