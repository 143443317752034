@use '../styles/variables';
@use '../styles/mixins';

.mycode {
  &__link {
    text-decoration: none;
    color: variables.$color-gray-6;
    display: flex;
    width: 100%;
  }
  &__header {
    height: 10vh;
  }

  &__main {
    height: 90vh;

    @include mixins.flexCenter;
  }

  &__response {
    height: 8%;
    display: flex;
    border-bottom: 1px solid variables.$color-gray-4;

    &:hover {
      background-color: variables.$color-gray-1;
    }

    &-box {
      width: 65%;
      height: 95%;
      overflow-y: scroll;

      &-menu {
        display: flex;
        gap: 50px;
        padding: 3rem 0px;

        &--dropdown {
        }

        &--searchbar {
          @include mixins.flexCenter;
          height: 4rem;
          width: 36rem;
          margin-top: 25px;
          gap: 10px;
          padding: 5px 15px;
          align-self: flex-end;
          border-radius: 20px;
          flex-direction: row-reverse;
          border: 1px solid variables.$color-gray-4;

          &:hover {
            box-shadow: 3px 3px 10px variables.$color-gray-3;
          }

          &-icon {
            font-size: variables.$default-font-size;
            @include mixins.flexCenter;
          }

          &-input {
            width: 100%;
            height: 100%;
            outline: none;
            padding-left: 5px;
            border: none;
            border-radius: 3px;
          }
        }
      }

      &-label {
        height: 7%;
        display: flex;
        font-size: variables.$default-font-size-small-1;
        border-bottom: 1px solid variables.$color-gray-4;

        &-category {
          width: 25%;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        &-content {
          width: 40%;
          display: flex;
          align-items: center;
          padding-left: 10px;

          @include mixins.respond(tab-port) {
            visibility: hidden;
          }
        }
        &-owner {
          width: 15%;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        &-created {
          width: 15%;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
      }
    }

    &--category {
      width: 25%;
      font-size: variables.$default-font-size-small-1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-icon {
        font-size: 25px;
        padding: 20px;
        @include mixins.flexCenter;
      }
    }
    &--content {
      width: 42%;
      font-size: variables.$default-font-size-small-1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;

      @include mixins.respond(tab-port) {
        visibility: hidden;
      }
    }
    &--owner {
      width: 15%;
      font-size: variables.$default-font-size-small-1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
    }
    &--created {
      width: 15%;
      font-size: variables.$default-font-size-small-1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
    }
    &--delete {
      width: 5%;
      @include mixins.flexCenter;

      &-icon {
        font-size: 1.8rem;
        color: variables.$color-gray-4;

        &:hover {
          color: variables.$color-gray-6 !important;
          cursor: pointer;
        }
      }
    }
  }
}
