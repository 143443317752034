@use '../styles/mixins';
@use '../styles/variables';
@use '../styles/utilities';

.signup {
  &__main {
    &-header {
      height: 14rem;

      &--title {
        font-size: variables.$default-font-size-3;
      }

      &--notice {
        font-size: variables.$default-font-size-small-2;
      }

      &-noticebox {
        padding: 10px 20px;
      }
    }
  }

  &__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-box {
      height: 19rem;
      @include mixins.flexCenter;
    }
  }

  &__input {
    width: 100%;
    height: 5rem;
    border: 1px solid variables.$color-gray-4;
    border-radius: 3px;
    padding-left: 20px;

    &:focus {
      outline: none;
      border: 1px solid variables.$color-success;
    }

    &::placeholder {
      color: variables.$color-gray-5;
      font-size: variables.$default-font-size;
    }

    &-box {
      background-color: antiquewhite;
      width: 100%;
      @include mixins.flexCenter;
      margin-bottom: 1rem;
    }

    &-btn {
      width: 100%;

      & button {
        width: 100%;
        height: 5rem;
        background-color: variables.$color-success;
        color: variables.$color-gray-0;
        border: none;
        border-radius: 2px;

        &:hover {
          background-color: variables.$color-success-hover;
        }
      }
    }
  }

  &__tologin {
    height: 5rem;
    @include mixins.flexCenter;
    font-size: variables.$default-font-size-small-1;

    & > p > a {
      color: variables.$color-success;
      text-decoration: none;
    }
  }
}
