@use '../../styles/variables';
@use '../../styles/mixins';

.section__accordion {
  padding: 10rem 20rem;

  @include mixins.respond(tab-land) {
    padding: 5rem 8rem;
  }

  @include mixins.respond(tab-port) {
    padding: 2rem 4rem;
  }

  @include mixins.respond(phone) {
    padding: 0.2rem 0.2rem;
  }

  &--header {
    &-main {
      display: block;
      text-align: center;
      padding: 5rem 0;
      font-size: variables.$default-font-size-6;
      font-weight: 700;
      background: linear-gradient(
        to right,
        variables.$color-primary-2,
        variables.$color-primary-8,
        variables.$color-primary-1,
        variables.$color-primary-6
      );
      // color: transparent;
      color: rgba(0, 0, 0, 0.1);
      background-clip: text;
      -webkit-background-clip: text;

      @include mixins.respond(tab-land) {
        font-size: variables.$default-font-size-4;
      }

      @include mixins.respond(tab-port) {
        font-size: variables.$default-font-size-3;
      }
      @include mixins.respond(phone) {
        font-size: variables.$default-font-size-2;
      }
    }

    &-sub {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem 0;
      font-size: variables.$default-font-size-4;
      color: variables.$color-primary-7;
      font-weight: 700;
      margin-bottom: 2rem;

      @include mixins.respond(tab-land) {
        font-size: variables.$default-font-size-2;
      }

      @include mixins.respond(tab-port) {
        font-size: variables.$default-font-size-1;
      }
      @include mixins.respond(phone) {
        font-size: variables.$default-font-size;
      }
    }
  }

  &--content {
    border-radius: 15px;
    box-shadow: 10px 10px 20px variables.$color-gray-3;
    padding: 10rem;

    @include mixins.respond(tab-land) {
      padding: 6rem;
    }
    @include mixins.respond(tab-port) {
      padding: 3rem;
    }

    @include mixins.respond(phone) {
      padding: 0.5rem;
    }
  }
}
