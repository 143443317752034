@use '../../styles/mixins';
@use '../../styles/variables';

.pg-cl {
  &-section {
    width: 100%;
    height: 100%;

    &__menubar {
      height: 15%;
      width: 100%;
      padding: 1rem 2rem 2rem 2rem;
    }
  }
}
