@use '../styles/mixins';
@use '../styles/variables';

.nav {
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid variables.$color-gray-3;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: variables.$color-white;
  z-index: 9999;

  &__logobox {
    height: 100%;
    width: 5%;
    @include mixins.flexCenter;
  }

  &__link-box {
    height: 100%;
    @include mixins.flexCenter;
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: inline-block;
    margin: 0 2rem;

    @include mixins.respond(phone) {
      margin: 0 0.8rem;
    }

    & a.active {
      color: variables.$color-primary-6;
    }
  }

  &__link {
    text-decoration: none;
    font-size: variables.$default-font-size-small-1;
    font-weight: 400;
    color: variables.$color-gray-6;
    transition: all 0.2s;

    &:hover {
      color: variables.$color-gray-7;
    }

    &-icon {
      @include mixins.flexCenter;
      text-decoration: none;
      color: variables.$color-gray-6;
      transition: all 0.2s;

      &:hover {
        color: variables.$color-gray-7;
      }
    }
  }

  &__input {
    border: none;
    color: variables.$color-gray-6;
    font-size: variables.$default-font-size-small-1;
    background-color: variables.$color-white;
    cursor: pointer;

    &:hover {
      color: variables.$color-gray-7;
    }
  }
}
