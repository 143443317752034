@use '../styles/variables';
@use '../styles/mixins';

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.4);

  &__content {
    width: 50%;
    height: 50%;
    margin: 10% auto;
    padding: 5rem;
    background-color: variables.$color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--form {
      &-name {
        display: block;
        margin-bottom: 2.5rem;

        & > label {
          display: block;
          font-size: variables.$default-font-size;
        }
        & > input {
          width: 100%;
          height: 3.5rem;
          padding: 10px;
          outline: none;
          border: 1px solid variables.$color-primary-6;
          border-radius: 3px;
        }
      }

      &-prompt {
        display: block;
        margin-bottom: 2.5rem;

        & > label {
          display: block;
          font-size: variables.$default-font-size;
        }
        & > textarea {
          width: 100%;
          height: 14rem;
          resize: none;
          padding: 10px;
          outline: none;
          border: 1px solid variables.$color-primary-6;
          border-radius: 3px;
        }
      }
    }

    &--btn {
      display: flex;
      justify-content: end;
      align-items: center;

      & > button:first-child {
        background-color: variables.$color-success;
        border: none;
        padding: 1rem 2rem;
        border-radius: 10px;
        color: variables.$color-white;
        cursor: pointer;
        transition: all 0.2s;
        margin-right: 5px;

        &:hover {
          background-color: variables.$color-success-hover;
        }
      }

      & > button:last-child {
        background-color: variables.$color-danger;
        border: none;
        padding: 1rem 2rem;
        border-radius: 10px;
        color: variables.$color-white;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: variables.$color-danger-hover;
        }
      }
    }
  }
}
