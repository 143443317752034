@use './../styles/variables';
@use './../styles/mixins';

.accordion {
  height: auto;

  &__item {
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    height: 12rem;
    color: variables.$color-primary-5;

    @include mixins.respond(phone) {
      display: block;
      height: auto;
    }

    &:nth-child(1) {
      .accordion__expanded {
        transform: translateY(15rem);
      }
    }
    &:nth-child(2) {
      .accordion__expanded {
        transform: translateY(5rem);
      }
    }
    &:nth-child(3) {
      .accordion__expanded {
        transform: translateY(-10rem);
      }
    }
    &:nth-child(4) {
      .accordion__expanded {
        transform: translateY(-20rem);
      }
    }
    &:nth-child(5) {
      .accordion__expanded {
        transform: translateY(-30rem);
      }
    }

    &:last-child {
      .accordion__expanded {
        transform: translateY(-45rem);
      }
    }
  }

  &__btn {
    &-box {
      flex-direction: column;
      width: 50rem;
      height: 100%;
    }
  }

  &__title {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: variables.$default-font-size-2;
    width: 100%;
    font-weight: 400;
    margin-bottom: 1rem;

    @include mixins.respond(tab-land) {
      font-size: variables.$default-font-size-1;
    }

    @include mixins.respond(tab-port) {
      font-size: 1.8rem;
    }

    @include mixins.respond(phone) {
      font-size: variables.$default-font-size;
    }

    & > span {
      margin-right: 1rem;
      color: variables.$color-primary-7;
      font-weight: 700;
    }
  }

  &__msg {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: variables.$default-font-size-small-1;
    text-align: left;
    margin-bottom: 1rem;

    @include mixins.respond(tab-land) {
      font-size: variables.$default-font-size-small-1;
    }
    @include mixins.respond(tab-port) {
      font-size: variables.$default-font-size-small-1;
    }
    @include mixins.respond(phone) {
      font-size: variables.$default-font-size-small-1;
    }
  }

  &__panel {
    // background-image: url('../images/data-center-network-3wz76kas9x1nwy42.jpeg');
    // background-size: cover;
    height: 40rem;

    @include mixins.respond(phone) {
      display: none;
    }
  }

  &__img {
  }

  &__collapse {
    overflow: hidden;
    display: none;
    opacity: 0;
  }

  &__expanded {
    width: 100%;
    height: 100%;
  }
}
