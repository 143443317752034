@use '../../styles/mixins';
@use '../../styles/variables';
@use '../../styles/utilities';

.auth {
  overflow: hidden;

  &__header {
    width: 100%;
    height: 6rem;

    @include mixins.flexCenter;

    &-logo {
      @include mixins.flexCenter;
    }
  }

  &__main {
    width: 100%;
    height: 65rem;
    @include mixins.flexCenter;

    &-box {
      width: 30rem;
      height: 56rem;
    }

    &-content {
      height: 42rem;
    }
  }

  &__divider {
    height: 3rem;
    @include mixins.flexCenter;

    & > span::before {
      content: '';
      display: inline-block;
      border-bottom: 1px solid variables.$color-gray-4;
      height: 0.5rem;
      width: 12rem;
      margin-right: 5px;
      margin-bottom: 2px;
    }
    & > span::after {
      content: '';
      display: inline-block;
      border-bottom: 1px solid variables.$color-gray-4;
      height: 0.5rem;
      width: 12rem;
      margin-left: 5px;
      margin-bottom: 2px;
    }
  }

  &__oauth {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &--form {
      display: block;
      width: 100%;
      height: 100%;
    }

    &--google {
      width: 100%;
      margin: 5px auto;

      &-link {
        display: flex;
        // justify-content: center;
        align-items: center;
        gap: 10px;
        text-decoration: none;
        color: variables.$color-gray-7;
        font-size: variables.$default-font-size-small-1;
        width: 100%;
        border: 1px solid variables.$color-gray-5;
        padding: 12px 22px;
        border-radius: 4px;

        &:hover {
          background-color: variables.$color-gray-1;
        }
      }

      &-btn {
      }

      &-icon {
        font-size: 20px;
        display: flex;
      }

      & > button {
        width: 100%;
        padding: 13px;
        border: none;
        border: 1px solid variables.$color-gray-4;
      }
    }
  }

  &__footer {
    width: 100%;
    height: 6rem;
    @include mixins.flexCenter;
    color: variables.$color-gray-6;
    font-size: variables.$default-font-size-small-1;
    border-top: 1px solid variables.$color-gray-1;

    & a {
      text-decoration: none;
      color: variables.$color-gray-6;
    }

    @include mixins.respond(tab-land) {
    }
  }
}
