@use '../styles/typography';
@use '../styles/mixins';
@use '../styles/variables';

.footer {
  background-color: variables.$color-white;
  padding: 5rem 0;
  margin-top: 3rem;
  border-top: 1px solid variables.$color-gray-3;

  &__headerbox {
    @include mixins.flexCenter;
    margin-bottom: 5rem;

    font-size: variables.$default-font-size-2;
    font-weight: 700;
    background: linear-gradient(
      to right,
      variables.$color-primary-2,
      variables.$color-primary-8,
      variables.$color-primary-1,
      variables.$color-primary-6
    );
    // color: transparent;
    color: rgba(0, 0, 0, 0.1);
    background-clip: text;
    -webkit-background-clip: text;
  }

  &__main {
    background: linear-gradient(
      to right,
      variables.$color-primary-8,
      variables.$color-primary-5
    );
    clip-path: polygon(10% 0, 90% 0, 100% 100%, 0 100%);
    padding: 7rem 12rem;
    display: flex;
    justify-content: center;
    align-items: stretch;

    @include mixins.respond(phone) {
      display: block;
    }
  }

  &__section {
    font-size: variables.$default-font-size;
    color: variables.$color-gray-3;
    padding: 2rem 4rem;

    @include mixins.respond(tab-land) {
      padding: 0.5rem 1rem;
    }

    &--title {
      font-size: variables.$default-font-size-2;
      font-weight: 500;
      display: flex;
      padding: 0.5rem 4rem;
      border-bottom: 1px solid variables.$color-gray-3;
    }
    &--list {
      padding: 0.5rem 0.5rem;
    }
    &--item {
    }
    &--logo {
      @include mixins.flexCenter;
      margin-right: 1rem;
    }
    &--text {
      padding: 1rem 4rem;
    }
    &--sns {
      padding: 1rem 4rem;
    }
  }

  &__snsbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__snslist {
    list-style: none;
    display: flex;
  }

  &__snsitem {
    background-color: white;
    margin: 0 0.5rem;

    width: 45px;
    height: 45px;
    border-radius: 100%;
    color: variables.$color-primary-8;

    @include mixins.flexCenter;
  }
}
