@use '../styles/variables';
@use '../styles/mixins';

.header {
  background-size: cover;
  background-image: linear-gradient(
      to right bottom,
      rgba(variables.$color-gray-9, 0.85),
      rgba(variables.$color-gray-9, 0.85)
    ),
    url('../images/premium_photo-1683121710572-7723bd2e235d.jpg');

  height: 85vh;
  width: 100%;
  color: variables.$color-white;
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-codebuddy {
      color: variables.$color-primary-4;
      font-weight: 700;
    }
  }

  &__btn {
    &--gotopg {
      font-size: variables.$default-font-size-1;
      font-weight: 700;
      padding: 15px 25px;
      border-radius: 3px;
      background: linear-gradient(
        to right,
        variables.$color-primary-6,
        variables.$color-primary-4
      );
      border: none;
      color: variables.$color-gray-1;
      box-shadow: 10px 10px 20px variables.$color-gray-7;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        transform: translateY(-4px);
        box-shadow: 5px 5px 10px variables.$color-gray-7;
        background: linear-gradient(
          to right,
          variables.$color-primary-8,
          variables.$color-primary-5
        );
        border: none;
        color: variables.$color-gray-1;
      }
      &:active {
        transform: translateY(-2px);
        box-shadow: 5px 5px 10px variables.$color-gray-7;
        background: linear-gradient(
          to right,
          variables.$color-primary-8,
          variables.$color-primary-5
        );
        border: none;
        color: variables.$color-gray-1;
      }
    }
  }
}

.typewriter {
  display: flex;
  width: 100%;
  padding: 0 5rem;
  @include mixins.flexCenter;

  @include mixins.respond(phone) {
    padding: 0 0rem;
  }

  &__box {
    width: 35rem;
    height: 20rem;
  }

  &__heading-box {
    @include mixins.flexCenter;
    background-color: variables.$color-gray-7;
    color: variables.$color-gray-3;
    width: 100%;
    height: 18%;
    display: flex;
    justify-content: flex-start;
    padding-left: 1.5rem;

    &:not(:last-child) {
      border-right: 1px solid variables.$color-gray-6;
    }
  }

  &__heading {
  }

  &__editor {
    @include mixins.flexCenter;
    background-color: variables.$color-black;
    height: 100%;
    width: 100%;
    color: variables.$color-gray-3;
    padding: 1rem 1rem;
    resize: none;
    outline: none;
    line-height: 30px;

    &-input {
      font-size: variables.$default-font-size-small-1;
      display: flex;
      justify-content: left;
      align-items: flex-start;
    }

    &-output {
      font-size: variables.$default-font-size-small-1;
      display: flex;
      justify-content: left;
      align-items: flex-start;

      resize: none;
      overflow: auto;
      width: 100%;
      height: 100%;
      padding: 10px;
      display: inline-block;

      &:focus {
        outline: none;
      }
    }
  }
}

.Typewriter__wrapper {
}
.Typewriter__cursor {
}
