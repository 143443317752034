@use '../styles/variables';
@use '../styles/mixins';

.button {
  padding: 10px 20px;
  font-size: 1.9rem;
  background-color: #fff;
  box-shadow: 5px 5px 10px variables.$color-gray-5;
  transition: 0.1s;
  width: 10rem;
  height: 5rem;
  @include mixins.flexCenter;

  @include mixins.respond(tab-land) {
    font-size: 1.7rem;
  }
  @include mixins.respond(tab-port) {
    font-size: 1.5rem;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 4px 4px 10px variables.$color-gray-5;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 5px 5px 10px variables.$color-gray-5;
  }
}

.rounded {
  border-radius: 5px;
}

.outlined {
  background-color: variables.$color-white !important;
  color: variables.$color-black !important;
}

.primary {
  background-color: variables.$color-primary-6;
  color: variables.$color-white;
  border: 1px solid variables.$color-primary-6;

  &:hover {
    background-color: variables.$color-primary-7;
    border: 1px solid variables.$color-primary-7;
  }
}

.secondary {
  background-color: variables.$color-primary-0;
  color: variables.$color-primary-8;
  border: 1px solid variables.$color-primary-0;

  &:hover {
    background-color: variables.$color-primary-1;
    border: 1px solid variables.$color-primary-1;
  }
}

.success {
  background-color: variables.$color-success;
  color: variables.$color-white;
  border: 1px solid variables.$color-success;

  &:hover {
    background-color: variables.$color-success-hover;
    border: 1px solid variables.$color-success-hover;
  }
}

.danger {
  background-color: variables.$color-danger;
  color: variables.$color-white;
  border: 1px solid variables.$color-danger;

  &:hover {
    background-color: variables.$color-danger-hover;
    border: 1px solid variables.$color-danger-hover;
  }
}

.warning {
  background-color: variables.$color-warning;
  color: variables.$color-white;
  border: 1px solid variables.$color-warning;

  &:hover {
    background-color: variables.$color-warning-hover;
    border: 1px solid variables.$color-warning-hover;
  }
}

.info {
  background-color: variables.$color-info;
  color: variables.$color-white;
  border: 1px solid variables.$color-info;

  &:hover {
    background-color: variables.$color-info-hover;
    border: 1px solid variables.$color-info-hover;
  }
}

.disabled {
  background-color: variables.$color-gray-5;
  border: 1px solid variables.$color-gray-5;
  transform: none;
  box-shadow: none;
  cursor: not-allowed;

  &:hover {
    background-color: variables.$color-gray-5;
    border: 1px solid variables.$color-gray-5;
    transform: none;
    box-shadow: none;
  }
}

.spin-loader {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
