@use '../styles/variables';
@use '../styles/mixins';

.sidebar {
  width: 100%;
  height: 100%;
  margin-right: 10px;

  &__list {
    list-style: none;
    width: 100%;
    box-shadow: 2px 2px 4px variables.$color-gray-4;
    border-radius: 10px;
  }

  &__item {
    margin: 1rem 0;

    &:hover {
      background-color: variables.$color-primary-0;
    }

    & a.active {
      background-color: variables.$color-primary-7;
      border-radius: 5px;
      color: variables.$color-white;
      box-shadow: 3px 3px 8px variables.$color-gray-6;
    }
  }

  &__link {
    text-decoration: none;
    font-size: variables.$default-font-size-small-2;
    color: variables.$color-gray-8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem;
  }

  &__icon {
    font-size: variables.$default-font-size-2;
    margin-right: 1rem;
    @include mixins.flexCenter;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    & > button {
      width: 100%;
      background-color: variables.$color-white;
      border: none;
      padding: 1.5rem 2.5rem;
      border-radius: 15px;
      font-size: variables.$default-font-size-small-1;
      cursor: pointer;
      transition: all 0.2s;
      color: variables.$color-gray-8;
      box-shadow: 2px 2px 5px variables.$color-gray-5;
      display: flex;
      justify-content: center;
      align-items: center;

      & > .plus-icon {
        font-size: 2.5rem;
        color: variables.$color-primary-7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
      }

      & > span {
        font-size: variables.$default-font-size-small-2;
      }

      &:hover {
        background-color: variables.$color-gray-0;
        color: variables.$color-gray-8;
        box-shadow: 5px 5px 10px variables.$color-gray-5;
      }
    }
  }
}

.category {
  &__list {
    list-style: none;
    width: 100%;
    box-shadow: 2px 2px 4px variables.$color-gray-4;
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  &__item {
    display: flex;

    &:hover {
      background-color: variables.$color-primary-0;
    }
  }

  &__link {
    width: 90%;
    text-decoration: none;
    font-size: variables.$default-font-size-small-2;
    color: variables.$color-gray-8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
  }

  &__del-btn {
    width: 10%;
    @include mixins.flexCenter;
    font-size: variables.$default-font-size-small-1;

    & > svg {
      color: variables.$color-gray-5;
      &:hover {
        color: variables.$color-gray-8;
      }
    }
  }
}
