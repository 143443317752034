@use '../../styles/mixins';
@use '../../styles/variables';

.playground {
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: scroll;

  @include mixins.respond(phone) {
    display: block;
    overflow: scroll;
  }

  &__sidebar {
    width: 30rem;
    margin-right: 5px;
    display: flex;

    @include mixins.respond(phone) {
      width: 100%;
      height: 50rem;
    }

    &-dragbar {
      background-color: variables.$color-gray-0;
      width: 10px;
      height: 100vh;
      cursor: col-resize;
    }

    &-pulloutBtn {
      width: 60px;
      display: none;
      visibility: hidden;
      position: relative;
      &--icon {
        @include mixins.flexCenter;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 40px;
        height: 60px;
        padding: 4.5px 1px;
        border-radius: 0 10px 10px 0;
        box-shadow: 1px 1px 2px variables.$color-gray-3;
        font-size: variables.$default-font-size-6;
        transition: all 0.2s;

        &:hover {
          background-color: variables.$color-gray-0;
        }
      }
    }
  }

  &__outlet {
    width: 100%;
  }
}

.pg {
  &-section {
    width: 100%;
    height: 100%;

    &__content {
      height: 75%;
      width: 100%;
      display: flex;
      border: 0.01px solid variables.$color-gray-2;
      border-radius: 5px;
      margin-bottom: 2rem;

      @include mixins.respond(phone) {
        display: block;
      }

      &--input {
        width: 47%;
        overflow-y: scroll;

        @include mixins.respond(phone) {
          width: 100%;
        }
      }

      &--arrow {
        width: 6%;
        display: flex;
        align-items: center;

        @include mixins.respond(phone) {
          width: 100%;
          @include mixins.flexCenter;
          margin-bottom: 1rem;
        }
      }

      &--output {
        width: 47%;

        @include mixins.respond(phone) {
          width: 100%;
          height: 100rem;
        }

        &-code {
          height: 65%;
          @include mixins.flexCenter;

          & > textarea {
            border: 1px solid variables.$color-primary-6;
            border-radius: 3px;
          }
        }

        &-text {
          height: 35%;
          @include mixins.flexCenter;

          & > textarea {
            border: 1px solid variables.$color-primary-6;
            border-radius: 3px;
          }
        }
      }
    }

    &__footer {
      height: 10%;
      display: flex;

      @include mixins.respond(phone) {
        display: block;
      }

      &-left {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 7rem;
        padding-bottom: 1rem;

        @include mixins.respond(phone) {
          width: 100%;
        }
      }

      &-right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 3.5rem;
        gap: 2rem;

        @include mixins.respond(phone) {
          width: 100%;
        }

        &-promotext {
          font-style: italic;
          font-size: variables.$default-font-size-small-1;

          & b {
            color: variables.$color-primary-6;
          }

          & a {
            text-decoration: none;
            font-style: normal;
            color: variables.$color-primary-6;

            &:hover {
              color: variables.$color-primary-8;
            }
          }
        }
      }
    }
  }

  &-message {
    border-bottom: 1px solid variables.$color-gray-5;
    padding: 3px;
    display: flex;

    &:hover textarea {
      background-color: variables.$color-gray-0;
    }

    &:hover {
      background-color: variables.$color-gray-0;
      border-radius: 5px;
      cursor: pointer;
    }

    &:hover &__delete-icon {
      color: variables.$color-gray-5;
    }

    &:hover &__type-text {
      background-color: variables.$color-gray-3;
    }

    &__box {
      width: 100%;
    }

    &__type {
      width: 15%;
      @include mixins.flexCenter;
      font-size: variables.$default-font-size;
      font-weight: 800;

      &-text {
        padding: 5px 10px;
      }
    }

    &__input {
      width: 75%;
      @include mixins.flexCenter;

      & textarea {
        background-color: variables.$color-white;
        border: none;

        &::placeholder {
          font-size: variables.$default-font-size;
          color: variables.$color-gray-4;
        }
      }

      & textarea:focus {
        border: 1px solid variables.$color-primary-5;
        border-radius: 5px;
        background-color: variables.$color-white;
      }
    }

    &__delete {
      width: 10%;
      @include mixins.flexCenter;

      &-icon {
        font-size: 1.8rem;
        color: variables.$color-white;

        &:hover {
          color: variables.$color-gray-8 !important;
          cursor: pointer;
        }
      }
    }

    &__add {
      height: 5rem;
      font-size: 1.4rem;
      font-weight: 600;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        background-color: variables.$color-gray-0;
        cursor: pointer;
      }

      &-icon {
        font-size: 1.8rem;
        padding: 0 15px;
        @include mixins.flexCenter;
        margin-left: 5px;
      }
    }
  }

  &__textarea {
    resize: none;
    overflow: auto;
    width: 90%;
    height: 90%;
    padding: 10px;
    display: inline-block;
    margin: 10px auto;

    &:focus {
      outline: none;
    }
  }
}

.loading-spinner-code {
  background-image: url(https://i.gifer.com/H8Al.gif);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.loading-spinner-text {
  background-image: url(https://i.gifer.com/H8Al.gif);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}
