@use '../../../styles/variables';
@use '../../../styles/mixins';

.CustomCategoryPage {
  height: 15%;
  width: 100%;
  padding: 0rem 2rem 4rem 2rem;

  &__title {
    font-size: variables.$default-font-size-small-1;
    margin-bottom: 3px;
    font-weight: 600;
  }

  &__prompt {
    resize: none;
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 1rem;
    overflow-y: auto;
  }

  &__errormsg {
    padding: 10px;
    font-size: variables.$default-font-size-small-1;
  }
}
