// COLORS
$color-primary-0: #ecfeff;
$color-primary-1: #cffafe;
$color-primary-2: #a5f3fc;
$color-primary-3: #67e8f9;
$color-primary-4: #22d3ee;
$color-primary-5: #06b6d4;
$color-primary-6: #0891b2;
$color-primary-7: #0e7490;
$color-primary-8: #155e75;
$color-primary-9: #164e63;

$color-success: #51cf66;
$color-success-hover: #307e3d;
$color-danger: #fa5252;
$color-danger-hover: #952e2e;
$color-warning: #fab005;
$color-warning-hover: #896104;
$color-info: #06b6d4;
$color-info-hover: #036f82;

$color-gray-0: #f8f9fa;
$color-gray-1: #f1f3f5;
$color-gray-2: #e9ecef;
$color-gray-3: #dee2e6;
$color-gray-4: #ced4da;
$color-gray-5: #adb5bd;
$color-gray-6: #868e96;
$color-gray-7: #495057;
$color-gray-8: #343a40;
$color-gray-9: #212529;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size-small-4: 0.8rem;
$default-font-size-small-3: 1rem;
$default-font-size-small-2: 1.2rem;
$default-font-size-small-1: 1.4rem;
$default-font-size: 1.6rem;
$default-font-size-1: 2rem;
$default-font-size-2: 2.4rem;
$default-font-size-3: 2.8rem;
$default-font-size-4: 3.2rem;
$default-font-size-5: 3.6rem;
$default-font-size-6: 4rem;
