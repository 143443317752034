@use './mixins';
@use './variables';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem=10px

  @include mixins.respond(tab-land) {
    font-size: 56.25%; // 1rem=9px
  }

  @include mixins.respond(tab-port) {
    font-size: 50%; // 1rem=8px
  }

  @include mixins.respond(phone) {
    font-size: 43.75%; // 1rem=7px
  }

  @include mixins.respond(big-desktop) {
    font-size: 75%; // 1rem=12px
  }
}

body {
  box-sizing: border-box;
  padding: 3rem;

  @include mixins.respond(tab-land) {
    padding: 0;
  }
}
